<template>
    <MDBContainer fluid class="px-4">
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
            </div>
        </div>
        <MDBRow center class="align-items-center" style="height: 100vh" v-if="!loadingSpinner">
            <MDBCol sm="4">
                <MDBCard>
                    <MDBCardBody class="d-grid p-5">

                        <MDBCardTitle class="text-center mb-4">
                            <img src="@/assets/VVV/vvv-colored.png" height="50" aria-hidden="true" alt="CSD_Logo" />
                            <h5 class="mt-4 fw-bold">Reset Password</h5>
                        </MDBCardTitle>
                        <MDBCardText v-if="!isLinkValid">
                            <div class="text-center mb-3">
                                <h5>This link is broken/invalid.</h5>
                                <p>This link might have been expired.</p>
                            </div>
                        </MDBCardText>
                        <MDBCardText v-else-if="isLinkValid">
                            <form id="reset-form">
                                <MDBInput label="Password" type="password" v-model="form.password" />
                                <MDBInput label="Confirm Password" type="password" v-model="confirmPassword"
                                    :helper="!isSamePassword ? 'Password does not match!' : ''" />
                                <password-meter @score="onScore" :password="form.password" />
                            </form>
                        </MDBCardText>
                        <div class="d-flex justify-content-center gap-4">
                            <div class="d-flex justify-content-center gap-2 gap-md-4">
                                <router-link :to="{ name: 'Login' }" custom v-slot="{ href, navigate }">
                                    <MDBBtn class="fw-bold" tag="a" color="primary" @click.stop="navigate" :href="href"
                                        size="md">
                                        Back to Login
                                    </MDBBtn>
                                </router-link>
                                <MDBBtn class="fw-bold" v-if="isLinkValid" style="background-color: #223698;"
                                    color="primary" @click="submit" :disabled="isSubmitBtnDisabled" size="md">Reset
                                </MDBBtn>
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title>
                {{ toastObject.title }}
            </template>
            {{ toastObject.message }}
        </MDBToast>
    </MDBContainer>
</template>

<script setup>
import PasswordMeter from "vue-simple-password-meter";
import { MDBToast, MDBInput, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn } from "mdb-vue-ui-kit";
import axios from "axios";
import { ref, onBeforeMount, reactive, computed } from "vue";
import { useTitle } from "@vueuse/core";
import { useRoute } from "vue-router";

useTitle("Reset Password | Very Viral Videos");

onBeforeMount(async () => {
    loadingSpinner.value = true;

    await axios
        .get("api/users/" + link + "/Reset")
        .then(function (response) {
            isLinkValid.value = true;
            if (response == undefined || response.status != 200) {
                isLinkValid.value = false;
            }
            loadingSpinner.value = false;
        })
        .catch(function () {
            isLinkValid.value = false;
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request.";
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
            loadingSpinner.value = false;
        });
});

const toastObject = ref({
    state: false,
    message: "",
    color: "",
    icon: "",
    title: "",
});

let loadingSpinner = ref(false);
const route = useRoute();
const link = route.params.link;

const form = reactive({
    password: "",
});

const confirmPassword = ref("");
const isSamePassword = computed(
    () => form.password === confirmPassword.value
);
const isSubmitBtnDisabled = computed(
    () => !isSamePassword.value || form.password == "" || form.confirmPassword == ""
);

const score = ref(null);
const onScore = (payload) => {
    score.value = payload.score;
};

const isLinkValid = ref(true);

const submit = () => {
    let formData = new FormData();
    formData.append("link", link);
    formData.append("password", form.password);

    loadingSpinner.value = true;
    axios
        .post("api/users/resetpassword", formData)
        .then(function (response) {
            if (response.status == 200) {
                toastObject.value.title = "Success!";
                toastObject.value.state = true;
                toastObject.value.message = "Successfully submitted!";
                toastObject.value.color = "success";
                toastObject.value.icon = "fas fa-check fa-lg me-2";
                loadingSpinner.value = false;
            }
        })
        .catch(function () {
            toastObject.value.state = true;
            toastObject.value.title = "Error!";
            toastObject.value.message = "An error occurred during request.";
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
            loadingSpinner.value = false;
        });
};
</script>

<style lang="scss" scoped>
.btn-primary {
    background-color: #223698;
}

:deep(.form-outline .form-helper) {
    color: red;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

#reset-form {
    display: grid;
    row-gap: 15px;
}
</style>
